<template>
	<div>
		<MainHeader />
		<form method="post" class="form-horizontal">
			<div class="alert alert-info row">
				<div class="col-md-8">
					<div class="row">			
						<div class="col-md-2">
							<router-link :to="{ name: 'home' }" class="btn btn-primary">Volver</router-link>
						</div>
						<div class="col-md-8">
							<h3>Nuevo análisis</h3>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label class="control-label" for="analisis">Nombre Análisis:</label>
							<input 
								type="text" 
								class="form-control" 
								placeholder="Análisis" 
								autofocus="autofocus"
								v-model="formData.analisis"
								:disabled="isEditMode"
							/>
							<br />
							<br />
							<p><strong><u>Fechas para calcular el promedio</u></strong></p>
							<div class="row">
								<div class="col-md-6">
									<label class="control-label" for="desde">Desde:</label>
									<input type="date" id="desde" class="form-control" placeholder="Desde DD/MM/AAAA" v-model="formData.desde" :disabled="isEditMode" />
								</div>
								<div class="col-md-6">
									<label class="control-label" for="hasta">Hasta:</label>
									<input type="date" id="hasta" class="form-control" placeholder="Hasta DD/MM/AAAA" v-model="formData.hasta" :disabled="isEditMode" />
									<div style="color:#d00;padding-top:8px;text-align:right;">({{ calcularDiasDiff() }} Meses)</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div>
								<h3>Stock a pedir (En meses):</h3>
								<table class="meses-a-pedir-table">
									<tr>
										<th></th>
										<th>Pico</th>
										<th>MdQ</th>
										<th>BA</th>
									</tr>
									<tr>
										<td>A</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.A.pico"
												@change="updateAll"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.A.mdp"
												@change="updateAll"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.A.ba"
												@change="updateAll"
												:disabled="isEditMode"
											/>
										</td>
									</tr>
									<tr>
										<td>B</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.B.pico"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.B.mdp"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.B.ba"
												:disabled="isEditMode"
											/>
										</td>
									</tr>
									<tr>
										<td>C</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.C.pico"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.C.mdp"
												:disabled="isEditMode"
											/>
										</td>
										<td>
											<input 
												type="text" 
												class="form-control" 
												v-model="formData.stockPedir.C.ba"
												:disabled="isEditMode"
											/>
										</td>
									</tr>
								</table>
							</div>
							<div>
								<br />
								<label class="control-label" for="promedio-irregular">Promedio Irregular:</label>
								<input 
									type="text" 
									class="form-control" 
									id="promedio-irregular" 
									placeholder="Promedio Irregular" 
									title="Escriba cuantas veces considera que tiene que variar el promedio para ser considerado irregular"
									v-model="formData.promedioIrregular"
									:disabled="isEditMode"
								/>
							</div>
							<div class="clearfix"></div>
							<div style="color:#d00;padding:6px 16px;">Para decimales usar el punto y no la coma</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-md-6">
							<p><strong><u>Pedido Rápido</u></strong></p>
							<input type="radio" id="pedido-rapido-si" v-model="formData.pedidoRapido" value="1" :disabled="isEditMode" />
							<label class="control-label" for="pedido-rapido-si">Si</label>
							<br />
							<input type="radio" id="pedido-rapido-no" v-model="formData.pedidoRapido" value="0" :disabled="isEditMode" />
							<label class="control-label" for="pedido-rapido-no">No</label><br />
							(El pedido rápido solo muestra los artículos que recomienda pedir)
						</div>
						<div class="col-md-6">
							<p><strong><u>Comprar / Transferir</u></strong></p>
							<input type="radio" id="comprar-transferir" v-model="formData.comprarTransferir" value="1" :disabled="isEditMode" />
							<label class="control-label" for="comprar-transferir">Comprar + Transferir</label>
							<br />
							<input type="radio" id="solo-transferir" v-model="formData.comprarTransferir" value="0" :disabled="isEditMode" />
							<label class="control-label" for="solo-transferir">Solo transferir</label>
							<br />
							<br />
							<div class="row">
								<div class="col-md-12">
									<p><strong><u>Oferta</u></strong></p>
									<div class="col-md-4">
										<input type="radio" id="oferta-especial-no" v-model="formData.ofertaEspecial" value="0" :disabled="isEditMode" />
										<label class="control-label" for="oferta-especial-no">NO</label>
										<input type="radio" id="oferta-especial-si" v-model="formData.ofertaEspecial" value="1" :disabled="isEditMode" />
										<label class="control-label" for="oferta-especial-si">SI</label>
									</div>
									<div class="col-md-6">
										<label class="control-label" for="oferta-especial-porcentaje">% De Descuento</label>
										<input 
											type="text" 
											class="form-control" 
											name="oferta-especial-porcentaje" 
											id="oferta-especial-porcentaje" 
											value="" 
											placeholder="% Desc" 
											pattern="^[0-9]{0,3}(\.|,)?[0-9]{0,3}$" 
											title="Escriba el porcentaje de descuento" 
											:disabled="isEditMode"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div class="form-group">
						<div class="col-md-3">
							<p><strong><u>Artículos a Mostrar</u></strong></p>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-criticos" id="mostrar-criticos" v-model="formData.mostrarCriticos" :disabled="isEditMode" />
								<label class="control-label" for="mostrar-criticos">Críticos</label>
							</div>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-nuevos" id="mostrar-nuevos" v-model="formData.mostrarNuevos" :disabled="isEditMode" />
								<label class="control-label" for="mostrar-nuevos">Nuevos</label>
							</div>
						</div>
						<div class="col-xs-9">
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-alta-rotacion" id="mostrar-alta-rotacion" v-model="formData.mostrarAlta" :disabled="isEditMode" />
								<label class="control-label" for="mostrar-alta-rotacion">Productos A (alta rotación, hasta 70% de ventas en unidades)</label>
							</div>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-media-rotacion" id="mostrar-media-rotacion" v-model="formData.mostrarMedia" :disabled="isEditMode" />
								<label class="control-label" for="mostrar-media-rotacion">Productos B (media rotación, 25% de las ventas en unidades)</label>
							</div>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-baja-rotacion" id="mostrar-baja-rotacion" v-model="formData.mostrarBaja" :disabled="isEditMode" />
								<label class="control-label" for="mostrar-baja-rotacion">Productos C (baja rotación, 5% de las ventas en unidades)</label>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<hr />
					<div class="col-md-6">
						<div class="form-group">
							<p><strong><u>Orden</u></strong></p>
							<div class="clearfix"></div>
							<div class="col-md-12">
								<div class="col-xs-12">
									<input type="radio" id="orden-promedio" value="0" v-model="formData.orden" :disabled="isEditMode" />
									<label class="control-label" for="orden-promedio">Por Rotación (Críticos arriba)</label>
									<br />
									<input type="radio" id="orden-codigo" value="1" v-model="formData.orden" :disabled="isEditMode" />
									<label class="control-label" for="orden-codigo">Por código (Críticos arriba)</label>
									<br />
									<input type="radio" id="orden-codigo-solo" value="2" v-model="formData.orden" :disabled="isEditMode" />
									<label class="control-label" for="orden-codigo-solo">Por código solamente</label>
									<br />
									<input type="radio" id="orden-promedio" value="3" v-model="formData.orden" :disabled="isEditMode" />
									<label class="control-label" for="orden-promedio">Por promedio</label>
								</div>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<p><strong><u>Sucursales a Mostrar</u></strong></p>
							<div class="clearfix"></div>
							<div class="col-md-12">
								<div class="col-xs-12">
									<input type="checkbox" name="mostrar-pico" id="mostrar-pico" v-model="formData.mostrarPico" :disabled="isEditMode" />
									<label class="control-label" for="mostrar-pico">Mostrar Pico</label>
								</div>
								<div class="col-xs-12">
									<input type="checkbox" name="mostrar-mdp" id="mostrar-mdp" v-model="formData.mostrarMdp" :disabled="isEditMode" />
									<label class="control-label" for="mostrar-mdp">Mostrar MdP</label>
								</div>
								<div class="col-xs-12">
									<input type="checkbox" name="mostrar-ba" id="mostrar-ba" v-model="formData.mostrarBa" :disabled="isEditMode" />
									<label class="control-label" for="mostrar-ba">Mostrar BA</label>
								</div>
							</div>
							<div class="clearfix"></div>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<label class="col-md-12" for="brands">brands:</label>
						<div class="clearfix"></div>
						<div class="col-md-12">
							<div class="box-1">
								<div class="box-2">
									<!-- <brand-box 
										v-for="brand in brands" 
										:brand="brand" 
										:key="brand.id"
										v-model="selectedBrands"
									></brand-box> -->
									<div v-for="brand in brands" :key="brand.id_marca">
										<input 
											type="checkbox" 
											class="ch-brands" 
											name="brands[]" 
											:id="'id-' + brand.id_marca"
											:value="brand.id_marca" 
											v-model="formData.selectedBrands"
											:disabled="isEditMode"
										/>
										<label :for="'id-' + brand.id_marca">{{brand.nombre_marca}}</label>
										<div class="clearfix"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="clearfix"></div>
					<button type="button" @click="prepareOrder" class="btn btn-primary" v-if="!isEditMode">Continuar</button>
					<router-link :to="'/spreadsheet/' + route.params.id" class="btn btn-primary" v-if="isEditMode">Continuar</router-link>
					<Teleport to="#app">
						<div v-show="showModal" class="centered-modal">
							<h2>Confirmacion de pedido</h2>
							<div v-html="summary" class="modal-data"></div>
							<div class="modal-buttons">
								<button @click="cancelNewOrder" class="btn btn-default m-2">Cancelar</button>
								<button @click="save" class="btn btn-success m-2">Continuar</button>
							</div>
						</div>
					</Teleport>
				</div>
			</div>
		</form>
	</div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import MainHeader from '@/components/MainHeader.vue';
import { appState } from "@/components/appState.js";

const today = new Date();
const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()).toISOString().split('T')[0];
const todayDate = today.toISOString().split('T')[0];

const brands = ref([]);
const route = useRoute();
const router = useRouter();
const isEditMode = computed(() => !!route.params.id);
const showModal = ref(false);
const summary = ref('');
const order = ref({});


const formData = reactive({
	analisis: '',
	desde: sixMonthsAgo,
	hasta: todayDate,
	stockPedir: {
		A: {
			pico: '',
			mdp: '',
			ba: ''
		},
		B: {
			pico: '',
			mdp: '',
			ba: ''
		},
		C: {
			pico: '',
			mdp: '',
			ba: ''
		}
	},
	pedidoRapido: 0,
	comprarTransferir: 1,
	orden: 2,
	mostrarCriticos: true,
	mostrarNuevos: true,
	mostrarAlta: true,
	mostrarMedia: true,
	mostrarBaja: true,
	mostrarPico: true,
	mostrarMdp: true,
	mostrarBa: true,
	promedioIrregular: 5,
	ofertaEspecial: 0,
	selectedBrands: [],
});

const priceFormat = (number) => {
  number = Math.round(number);
  return number.toLocaleString();
}

function updateAll(event){
	Object.keys(formData.stockPedir).forEach((key) => {
		Object.keys(formData.stockPedir[key]).forEach((key2) => {
			if(formData.stockPedir[key][key2]==''){
				formData.stockPedir[key][key2] = event.target.value;
			}
		});
	});
}

function calcularDiasDiff(){
	var fecha1 = new Date(formData.desde.substring(0,4),formData.desde.substring(5,7),formData.desde.substring(8,10));
	var fecha2 = new Date(formData.hasta.substring(0,4),formData.hasta.substring(5,7),formData.hasta.substring(8,10));
	var diasDif = fecha2.getTime() - fecha1.getTime();
	var dias = Math.round(diasDif/(1000 * 60 * 60 * 24));
	if(dias>0){
		var meses = (dias+1)/30.5;
		meses = Math.round(meses * 10) / 10;
		return meses
	}
	return 0;
}

function cancelNewOrder(){
	showModal.value = false;
	appState.stopWaiting();
}

function prepareOrder(){
	appState.startWaiting();
	const payload = {
		"analisis": formData.analisis,
		"usuario": '',
		"desde": formData.desde,
		"hasta": formData.hasta,
		"pedir-pico-a": formData.stockPedir.A.pico,
		"pedir-pico-b": formData.stockPedir.B.pico,
		"pedir-pico-c": formData.stockPedir.C.pico,
		"pedir-mdp-a": formData.stockPedir.A.mdp,
		"pedir-mdp-b": formData.stockPedir.B.mdp,
		"pedir-mdp-c": formData.stockPedir.C.mdp,
		"pedir-ba-a": formData.stockPedir.A.ba,
		"pedir-ba-b": formData.stockPedir.B.ba,
		"pedir-ba-c": formData.stockPedir.C.ba,
		"pedido-rapido": formData.pedidoRapido ? 1 : 0,
		"comprar-transferir": formData.comprarTransferir,
		"orden": formData.orden,
		"mostrar-criticos": formData.mostrarCriticos ? 1 : 0,
		"mostrar-nuevos": formData.mostrarNuevos ? 1 : 0,
		"mostrar-alta": formData.mostrarAlta ? 1 : 0,
		"mostrar-media": formData.mostrarMedia ? 1 : 0,
		"mostrar-baja": formData.mostrarBaja ? 1 : 0,
		"mostrar-pico": formData.mostrarPico ? 1 : 0,
		"mostrar-mdp": formData.mostrarMdp ? 1 : 0,
		"mostrar-ba": formData.mostrarBa ? 1 : 0,
		"promedio-irregular": formData.promedioIrregular,
		"oferta-especial": formData.ofertaEspecial,
		"marcas": formData.selectedBrands,
	}
	axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
	var orderSaved = axios.post( process.env.VUE_APP_API + "orders", payload)
		.then( (response) => {
			console.log('asking confirmation ', response.data);
			// router.push({ name: 'saved-spreadsheet', params: { id: response.data.id }});
			order.value = { ...response.data };
			summary.value = `
				<table style="text-align:left;margin: 1em auto;">
					<tr style="background-color:#eee;">
						<td style="padding: 5px 2em;">Recomendados en U</td>
						<td style="padding: 5px 2em;text-align:right;">${response.data.recomendado_total_u}</td>
					</tr>
					<tr style="background-color:#fff;">
						<td style="padding: 5px 2em;">Recomendados en $</td>
						<td style="padding: 5px 2em;text-align:right;">${ priceFormat(response.data.recomendado_total_m) }</td>
					</tr>
					<tr style="background-color:#eee;">
						<td style="padding: 5px 2em;">stock en U</td>
						<td style="padding: 5px 2em;text-align:right;">${response.data.stock_total_u}</td>
					</tr>
					<tr style="background-color:#fff;">
						<td style="padding: 5px 2em;">stock en $</td>
						<td style="padding: 5px 2em;text-align:right;">${ priceFormat(response.data.stock_total_m) }</td>
					</tr>
					<tr style="background-color:#eee;">
						<td style="padding: 5px 2em;">pendientes en U</td>
						<td style="padding: 5px 2em;text-align:right;">${response.data.pendientes_total_u}</td>
					</tr>
					<tr style="background-color:#fff;">
						<td style="padding: 5px 2em;">pendientes en $</td>
						<td style="padding: 5px 2em;text-align:right;">${ priceFormat(response.data.pendientes_total_m) }</td>
					</tr>
				</table>
			`;
			showModal.value = true;

			// if (window.confirm(summary)) {
			// 	// console.log("confirmed")
			// 	save(response.data);
			// }
			// appState.stopWaiting();
		})
		.catch(error => {
			console.error("Error procesando los datos", error.message);
			appState.stopWaiting();
		});

	return orderSaved;
}

function save(){
	console.log(order.value)
	axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
	var orderSaved = axios.post( process.env.VUE_APP_API + "orders/confirm", order.value)
		.then( (response) => {
			console.log('response in save() ', response.data)
			router.push({ name: 'saved-spreadsheet', params: { id: response.data.id }});
		})
		.catch(error => {
			console.error("Error guardando los datos", error.message);
		});

	return orderSaved;
}

fetch( process.env.VUE_APP_API + "brands")
	.then( res => res.json() )
	.then( data => {
	brands.value = data
});

if( isEditMode.value ){
	// console.log('EDIT MODE');

	fetch( `${process.env.VUE_APP_API}orders/${route.params.id}`)
		.then( res => res.json() )
		.then( data => {
			console.log('data ---- ', data);
			formData.analisis = data.analisis;
			formData.desde = data.desde;
			formData.hasta = data.hasta;
			formData.stockPedir = {
				A: {
					pico: data.pedir_pico_a,
					mdp: data.pedir_mdp_a,
					ba: data.pedir_ba_a
				},
				B: {
					pico: data.pedir_pico_b,
					mdp: data.pedir_mdp_b,
					ba: data.pedir_ba_b
				},
				C: {
					pico: data.pedir_pico_c,
					mdp: data.pedir_mdp_c,
					ba: data.pedir_ba_c
				}
			};
			formData.pedidoRapido = data.pedido_rapido;
			formData.comprarTransferir = data.comprar_transferir;
			formData.orden = data.orden;
			formData.mostrarCriticos = Boolean(data.mostrar_criticos);
			formData.mostrarNuevos = Boolean(data.mostrar_nuevos);
			formData.mostrarAlta = Boolean(data.mostrar_alta);
			formData.mostrarMedia = Boolean(data.mostrar_media);
			formData.mostrarBaja = Boolean(data.mostrar_baja);
			formData.mostrarPico = Boolean(data.mostrar_pico);
			formData.mostrarMdp = Boolean(data.mostrar_mdp);
			formData.mostrarBa = Boolean(data.mostrar_ba);
			formData.selectedBrands = data.marcas.split(' ');
			console.log('savedData ---------> ', formData);
		}
	);
}

</script>

<style scoped>
.centered-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 1em 2em;
  min-width:30%;
  min-height: 10em;
  z-index:999999;
}
.modal-data{
  margin:1em;
  text-align:center;
}
.modal-data table{
	border:solid 1px #333;
}
.custom-table td{
  padding: 3px 1em;
}
.modal-buttons{
  margin:0.5em 1em;
  text-align:right;
}
.modal-buttons button{
  margin:0.5em;
}
</style>