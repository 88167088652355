<template>
    <main-header />
    <div class="alert alert-info">
        <h3>Análisis Realizados</h3>

        <!-- <a href="./new-order" class="btn btn-primary">Nuevo Análisis</a> -->
        <router-link to="new-order" class="btn btn-primary">Nuevo Análisis</router-link>
        | <input type="submit" class="btn btn-primary" name="borrar" value="Borrar" @click="borrar" />
        | <input type="button" class="btn btn-primary" name="actualizar-base" id="actualizar-base" value="Actualizar Base" />
        | <input type="button" name="analisis-rapido-criticos" id="analisis-rapido-criticos" class="btn btn-danger" value="Análisis rápido de críticos" />
        | <router-link to="calendario" class="btn btn-success">Calendario</router-link>
        | <a href="https://distrisuper.ddns.net/cruce-proveedor-1/" target="blank" class="btn btn-primary">Cruce de pendientes 2</a>
        | <select class="btn btn-primary" name="filtro-pedido" id="filtro-pedido" @change="filtroOrdenes">
            <option value="all">Todos</option>
            <option value="1">Pedido</option>
            <option value="0">No pedido</option>
          </select>
        
        <br /><br />
        <div class="inline-block" id="ultima-actualizacion"></div>
        <br />
        <table class="table table-bordered table-responsive listado-pc" id="tabla-totales" style="background:#f5f5f5;">
            <tr>
                <th></th>
                <th>Fecha</th>
                <th>Análisis</th>
                <th>Totales</th>
                <th>Transferir</th>
                <th>Pico</th>
                <th>MdP</th>
                <th>BA</th>
                <th>Exportado</th>
            </tr>
            <order-line 
                v-for="order in orders" 
                :order="order" 
                :key="order.id"
                @updateDeleteOrder="updateDeleteOrder"
            ></order-line>
        </table>
    </div>
</template>

<script> 
    import { ref } from "vue";
    import axios from 'axios';
    import OrderLine from "@/components/OrderLine.vue";
	import MainHeader from '@/components/MainHeader.vue';

    export default {
        components: {
            OrderLine,
            MainHeader,
        },

        setup(){
            const orders = ref([]);
            const deleteList = ref([]);

            // const filtro = { status: 0 };
            // const filtroQueryString = new URLSearchParams(filtro).toString();
            // const url = `${process.env.VUE_APP_API}orders?${filtroQueryString}`;
            
            // fetch(url)
            //     .then( res => res.json() )
            //     .then( data => {
            //         orders.value = data
            //     })

            const updateDeleteOrder = (e) => {
                // console.log(e)
                if( e.delete ){
                    deleteList.value.push(e.id)
                }else{
                    const index = deleteList.value.indexOf(e.id);
                    if (index > -1) {
                        deleteList.value.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
                // console.log(deleteList.value)
            }

            const borrar = () => {
                // console.log('deletelist', deleteList.value)
                if( confirm("¿Está seguro de borrar los análisis seleccionados?") ){
                    // console.log(process.env.VUE_APP_API + "orders/delete/" + key)

                    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                    axios.post( `${process.env.VUE_APP_API}orders/delete`, {'idsToDelete': deleteList.value})
                        .then( response => {
                            orders.value = response.data
                            deleteList.value = []
                            // console.log(orders.value)
                        })
                        .catch( (error) => {
                            deleteList.value = []
                            console.error("Error guardando los datos", error.message);
                        })

                }
            }

            function filtroOrdenes(e){
                var status = e.target.value;
                actualizarOrdenes(status);
            }

            function actualizarOrdenes(status){

                var url = `${process.env.VUE_APP_API}orders`;

                if( status == 0 || status == 1 ){
                    url = url + `?status=${status}`;
                }

                fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    orders.value = data;
                });
            }

            actualizarOrdenes('all');

            return {
                orders,
                OrderLine,
                borrar,
                updateDeleteOrder,
                filtroOrdenes
            }
        }
    }
</script>

<style scoped>
    .alert-info{
        margin:22px;
    }
</style>