<template>
    <div class="container" v-if="props.show">
        <div class="fake-input" @click="focusIn">&nbsp;{{ value }}</div>
        <input
            type="number"
            :min="0"
            :max="9999"
            :step="props.step"
            class="main-input"
            ref="myInput"
            :value="value" 
            v-if="isFocus"
            @focusout="focusOut"
        />
    </div>
</template>
  
  
<script setup>
// import { PropertyMixer } from 'three';
import { ref, defineProps, defineEmits, nextTick } from 'vue';

const props = defineProps({
    cp: {
        type: String,
    },
    recomended: {
        type: Number,
    },
    step: {
        type: Number,
    },
    value: {
        type: Number,
    },
    show: {
        type: Boolean,
    },
    transfer: {
        type: Boolean,
    },
});

// console.log('props: ', props);

const emit = defineEmits(["input"]);

// const functionInput = (e) => {
//     // console.log('e.target.value: ', e.target.value)
//     if( e.target.value > 0 && e.target.value < e.target.step ){
// 		alert('El lote mínimo para ' + props.cp + ' es ' + e.target.step);
// 	}
//     if( e.target.value > props.recomended ){
// 		alert('Atención estás pidiendo mas de lo recomendado');
// 	}
//     emit('input', e.target.value)
// }

const isFocus = ref(false);
const myInput = ref(null)

const focusIn = async () => {
    console.log('focus');
    isFocus.value = true;

    await nextTick()
    if (myInput.value) {
        myInput.value.focus()
    }
    
}
const focusOut = (e) => {
    var value = e.target.value ? e.target.value : 0;
    if( !props.transfer ){
        if( value > 0 && value < e.target.step ){
            alert('El lote mínimo para ' + props.cp + ' es ' + e.target.step);
        }
        if( value > props.recomended ){
            alert('Atención estás pidiendo mas de lo recomendado');
        }
    }

    emit('input', value)
    // console.log('blur');
    isFocus.value = false;
}
</script>

<style scoped>
.container{
margin:3px 0;
padding:0;
position:relative;
width:100%;
height:2em!important;
min-height:2em!important;
max-height:2em!important;
text-align:left;
}
.fake-input, .input-muted, .main-input{
position:absolute!important;
text-align:right;
width:100%;
min-height:1em;
border:solid 1px #ccc;
margin:0;
padding:2px;
text-indent:0.2em;
direction:rtl;
}
.fake-input{
background:#fff;
cursor:pointer;
}
.input-muted{
background:#ccc;
color:#777;
}  
.main-input{
z-index:1;
border:solid 2px #cc0000;
background:#FAFF5D;
-webkit-box-shadow: 0px 0px 6px 3px rgba(68,107,255,1);
-moz-box-shadow: 0px 0px 6px 3px rgba(68,107,255,1);
box-shadow: 0px 0px 6px 3px rgba(68,107,255,1);
}

.chequeado{
background:#cfe !important;
}

</style>