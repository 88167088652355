<template>
    <div class="custom-margin alert alert-info">
        <h3>Análisis Realizados</h3>

        <a href="./new-order" class="btn btn-primary">Nuevo Análisis</a>
        | <input type="submit" class="btn btn-primary" name="borrar" value="Borrar" />
        | <input type="button" class="btn btn-primary" name="actualizar-base" id="actualizar-base" value="Actualizar Base" />
        | <input type="button" name="analisis-rapido-criticos" id="analisis-rapido-criticos" class="btn btn-danger" value="Análisis rápido de críticos" />
        | <a href="./calendario" class="btn btn-success">Calendario</a>
        | <a href="https://distrisuper.ddns.net/cruce-proveedor-1/" target="blank" class="btn btn-primary">Cruce de pendientes 2</a>
        <br /><br />
        <div class="inline-block" id="ultima-actualizacion"></div>
        <br />
        <table class="table table-bordered table-hover table-calendario" style="background:#f5f5f5;">
            <tr class="alert-info">
                <th></th>
                <th v-for="(date, index) in columns" :key="index" colspan="3">
                    {{ spanishDate(date) }}
                </th>
            </tr>
            <tr class="alert-info">
                <th>Marcas</th>
                <template v-for="( date ) in columns" :key="date">
                    <th>
                        Coeficiente
                    </th>
                    <th>
                        Fechas
                    </th>
                    <th>
                        Importe
                    </th>
                </template>
            </tr>
            <tr v-for="(line, index) in matrix" :key="index">
                <td>{{ line.nombre_marca }}</td>
                <template v-for="( date ) in columns" :key="date">
                    <td v-if="Array.isArray(line[date])" class="align-left">
                        <div v-for="(item, itemIndex) in line[date]" :key="itemIndex">
                            <a :href="'/spreadsheet/' + item.id">
                                <span>Pico. A: {{ parseFloat(item.pedir_pico_a) }} - B: {{ parseFloat(item.pedir_pico_b) }} - C: {{ parseFloat(item.pedir_pico_b) }}</span><br />
                                <span>MdP. A: {{ parseFloat( item.pedir_mdp_a) }} - B: {{ parseFloat(item.pedir_mdp_b) }} - C: {{ parseFloat(item.pedir_mdp_c) }}</span><br />
                                <span>BA. A: {{ parseFloat( item.pedir_ba_a ) }} - B: {{ parseFloat( item.pedir_ba_b ) }} - C: {{ parseFloat( item.pedir_ba_c ) }}</span>
                            </a>
                        </div>
                    </td>
                    <td v-else>
                        
                    </td>
                    <td v-if="Array.isArray(line[date])">
                        <div v-for="(item, itemIndex) in line[date]" :key="itemIndex">
                            <a :href="'/spreadsheet/' + item.id">
                                <span>{{ spanishDate(item.fecha) }}</span><br />
                            </a><br />
                        </div>
                    </td>
                    <td v-else>
                        
                    </td>
                    <td v-if="Array.isArray(line[date])">
                        <div v-for="(item, itemIndex) in line[date]" :key="itemIndex">
                            <a :href="'/spreadsheet/' + item.id">
                                <span>{{ Math.round(item.p_total_m).toLocaleString('es-ES') }}</span><br />
                            </a><br />
                        </div>
                    </td>
                    <td v-else>
                        
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      columns: [],
      matrix: [],
    };
  },

  mounted() {
    axios.get(`${process.env.VUE_APP_API}calendar/`)
      .then(response => {
        this.columns = response.data.columns;
        this.matrix = response.data.matrix;
        // console.log(this.matrix, response.data.ordered_list);
      })
      .catch(error => {
        console.error(error);
      });
  },

  methods: {
    spanishDate(date) {
        const exploded = date.split('-').map(part => parseInt(part, 10));
        return exploded.reverse().join('-');
    }
  }
}
</script>

<style scoped>
    .custom-margin{
        margin:22px;
    }
    table{
        line-height: 1.42857143;
        vertical-align: top;
    }
    .table-calendario td, .table-calendario th {
        border: solid 1px #555;
        padding: 3px 8px;
        vertical-align: top;
    }
    .table-calendario td {
        min-width: 5em;
        text-align:right;
    }
    .table-calendario td:first-child {
        text-align:left;
    }
    .table-calendario td span{
        white-space: nowrap;
    }
    .align-left{
        text-align:left !important;
    }
</style>